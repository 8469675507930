<template>
  <base-page
    label="Расчет бонусов"
    :width="width"
    @click:back-button="$router.push({ name: 'bonus-program' })"
  >
    <div class="calculate-bonus mt-8">
      <table-filters
        class="calculate-bonus__filter-wrapper"
        can-change-point
        :date-begin="startDay"
        :date-end="endDay"
        :point-id="pointId"
      />

      <base-button is-new secondary small>Настройка диапазона</base-button>

      <base-table :columns="columns" :data="result">
        <template #label>
          <span class="body-2">Таблица распрелеления бонусов исходя из заданной формулы</span>
        </template>

        <template #uploadStatus="{ value }">
          <base-chip
            small
            :green="value === 'success'"
            :red="value === 'error'"
            :value="value === 'success' ? 'Успех' : 'Беда'"
          />
        </template>
      </base-table>

      <base-button is-new small primary>Распределить бонусы</base-button>
    </div>

    <upload-status-modal />
  </base-page>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import BaseButton from "@/components/BaseButton/BaseButton.vue";
import BaseTable from "@/components/BaseTable/ui/BaseTable.vue";
import BasePage from "@/components/BasePage/ui/base-page.vue";
import TableFilters from "@/components/TableFilters";
import { request } from "@/components-js/requestSrv";
import { format as dateFormat, addDays, endOfMonth, startOfMonth } from "date-fns";
import { dateFormatJS } from "@/components-js/dateFormat";
import { ru } from "date-fns/locale";
import BaseChip from "@/components/BaseChip/BaseChip.vue";
import BaseDragNDrop from "@/components/BaseDragNDrop/ui/base-drag-n-drop.vue";
import UploadStatusModal from "@/views/BonusProgramPage/ui/upload-status-modal.vue";
import { EVENTS } from "@/const";
import emitter from "@/lib/helpers/emitter";

const { width } = defineProps({
  width: { type: String, default: '679px' },
});

const selectedFile = ref(null);
const result = ref([]);
const hasFile = ref(false);
const startDay = ref("");
const endDay = ref("");
const pointId = ref();

const checkDate = (date, isEnd) => {
  if (date.getDate() <= 15 && !isEnd) return startOfMonth(date);
  if (date.getDate() > 15 && !isEnd) return addDays(startOfMonth(date), 15);
  if (date.getDate() <= 15 && isEnd) return addDays(startOfMonth(date), 14);
  if (date.getDate() > 15 && isEnd) return endOfMonth(date);
};

const setDataInRouteParams = () => {
  startDay.value = dateFormatJS(
    checkDate(new Date())
  );

  endDay.value = dateFormatJS(
    checkDate(new Date(), true)
  );
};
setDataInRouteParams();

const columns = computed(() => [
  {
    id: "date",
    label: "Дата",
    format: row => {
      return dateFormat(new Date(row.dateTime), "dd.MM.yy", { locale: ru });
    },
  },
  {
    id: "point",
    label: "Локация",
  },
  {
    id: "earnings",
    label: "Выручка",
  },
  {
    id: "percentRate",
    label: "Процент",
  },
  {
    id: "bonusRate",
    label: "Бонус",
  },
]);

const handleFileSelected = uploadedFile => {
  selectedFile.value = uploadedFile;

  hasFile.value = uploadedFile && typeof uploadedFile === "object";
};

const uploadCheck = () => {
  emitter.emit(EVENTS.SHOW_MODAL, {
    name: "upload-status",
    data: {
      status: false,
      fileName: "message.txt",
      uploadedPointsEarnings: [
        {
          pointName: "Ленина",
          period: "" || [],
          earning: 0,
        },
      ],
    },
  });

  selectedFile.value = null;
};

const mockData = [
  {
    dateTime: "2024-03-20T10:30:00",
    point: "Ленина",
    earnings: 150000,
    percentRate: 5,
    bonusRate: 7500
  },
  {
    dateTime: "2024-03-19T15:45:00",
    point: "Пушкина",
    earnings: 200000,
    percentRate: 5,
    bonusRate: 10000
  },
  {
    dateTime: "2024-03-18T09:15:00",
    point: "Гоголя",
    earnings: 180000,
    percentRate: 5,
    bonusRate: 9000
  },
  {
    dateTime: "2024-03-17T14:20:00",
    point: "Толстого",
    earnings: 220000,
    percentRate: null,
    bonusRate: null
  }
];

const getData = async () => {
  //result.value = await request("/api/sales/salesuploadtest", "GET");
  // Имитация задержки API
  await new Promise(resolve => setTimeout(resolve, 500));
  result.value = mockData;
};

onMounted(() => {
  getData();
});
</script>

<style scoped lang="scss">
@import "@/styles/ui/fontSize";

.calculate-bonus {
  display: flex;
  flex-direction: column;
  gap: var(--sp-6);
}
</style>
